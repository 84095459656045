import React, { useContext } from 'react'
import styled from 'styled-components'
import { ModalContext } from 'components/Modal'

const Card = styled.div`
  background-color: white;
  padding: 8px;
  color: black;
  > p {
    word-break: keep-all;
    :first-child {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  > a {
    margin: 3px;
  }
`

const StandardCard = ({ dealer }) => (
  <Card>
    <p>{dealer?.companyName}</p>
    <p>
      {dealer?.address.street} {dealer?.address.nr}
    </p>
    <p>
      {dealer?.address.postCode} {dealer?.address.city}
    </p>
  </Card>
)

const RedesignCard = ({ dealer }) => (
  <Card>
    <p>
      <b>{dealer?.companyName}</b>
    </p>
    <p>
      {dealer?.address.street} {dealer?.address.nr}
    </p>
    <p>
      {dealer?.address.postCode} {dealer?.address.city}
    </p>
    {dealer?.email && <p>E-post: {dealer?.email}</p>}
    <p>{dealer?.phone}</p>
  </Card>
)

export default function StoryblokModalDealerCard() {
  const { dealer, data } = useContext(ModalContext)
  const isRedesign = data?.component?.design === 'redesign'
  const Card = isRedesign ? RedesignCard : StandardCard
  return <Card dealer={dealer} />
}
